import * as yup from 'yup';
import { Identifiable } from 'shared/types';
import { WrappedProps } from 'shared/hocs/withDrawer';
import { getValidationResult } from 'shared/utils/tools';
import React from 'react';
import { Option } from 'shared/types';

export type PermissionOptionType = Option & {
  child_permissions?: Option[];
};

export const CAN_EDIT_PREFERENCES = 'can_edit_preferences';
export const CAN_CONFIRM_WORK_HOURS = 'can_confirm_work_hours';
export const CAN_ACCEPT_DECLINE_REQUESTS = 'can_accept_decline_requests';
export const CAN_GENERATE_WORKER_REPORTS = 'can_generate_reports_for_workers';
export const CAN_MANAGE_WORKSPACES = 'can_manage_workspaces';
export const CAN_MANAGE_WORKTYPES = 'can_manage_work_types';
export const CAN_MANAGE_SALARY = 'can_manage_salary_extensions';
export const CAN_MANAGE_BOXES = 'login_boxes_management';
export const CAN_MANAGE_USERS = 'can_add/edit_users';
export const CAN_MANAGE_USER_RIGHTS = 'can_manage_user_rights';
export const CAN_MANAGE_USER_WORK_HOURS = 'can_add/edit_work_hours_of_users';
export const CAN_REGISTER_OWN_WORK_FROM_COMPUTER = 'can_register_own_work_time_from_computer';
export const CAN_REGISTER_OWN_WORK_FROM_LOGIN_BOX = 'can_register_work_hours_from_login_box';
export const CAN_REGISTER_OWN_WORK_FROM_MOBILE = 'can_register_work_hours_through_mobile';
export const CAN_EDIT_OWN_WORK = 'can_edit_own_work_time';
export const CAN_EDIT_OWN_PROFILE = 'can_edit_own_profile';
export const CAN_GENERATE_REPORTS = 'can_generate_reports';
export const HAS_MANAGER_RIGHTS = 'has_manager_rights';

export const VISIBLE_ON_LOGIN_BOX = 'visible_on_login_box';
export const CAN_USE_TIMERS = 'can_use_timers';
export const TRACK_LOCATION = 'track_location';
export const CAN_QR_LOGIN = 'can_qr_login';
export const CAN_MANAGE_ADMIN_PAGES = 'can_manage_admin_pages';

export const MANAGER_PERMISSIONS_VALUES = [
  CAN_EDIT_PREFERENCES,
  CAN_CONFIRM_WORK_HOURS,
  CAN_GENERATE_WORKER_REPORTS,
  CAN_MANAGE_WORKSPACES,
  CAN_MANAGE_WORKTYPES,
  CAN_MANAGE_SALARY,
  CAN_MANAGE_BOXES,
  CAN_MANAGE_USERS,
  CAN_MANAGE_USER_RIGHTS,
  HAS_MANAGER_RIGHTS,
  CAN_MANAGE_USER_WORK_HOURS,
  CAN_ACCEPT_DECLINE_REQUESTS,
];

export const WORKER_PERMISSIONS_VALUES = [
  CAN_REGISTER_OWN_WORK_FROM_COMPUTER,
  CAN_REGISTER_OWN_WORK_FROM_LOGIN_BOX,
  CAN_REGISTER_OWN_WORK_FROM_MOBILE,
  CAN_EDIT_OWN_WORK,
  CAN_EDIT_OWN_PROFILE,
  CAN_GENERATE_REPORTS,
  VISIBLE_ON_LOGIN_BOX,
  CAN_USE_TIMERS,
  TRACK_LOCATION,
  CAN_QR_LOGIN,
];

export const MANAGER_PERMISSIONS = [
  {
    label: 'Can edit preferences',
    value: CAN_EDIT_PREFERENCES,
  },
  {
    label: 'Can add/edit users',
    value: CAN_MANAGE_USERS,
  },
  {
    label: 'Can generate reports for workers',
    value: CAN_GENERATE_WORKER_REPORTS,
  },
  {
    label: 'Can manage user work hours',
    value: CAN_MANAGE_USER_WORK_HOURS,
  },
  {
    label: 'Can manage workspaces',
    value: CAN_MANAGE_WORKSPACES,
  },
  {
    label: 'Can manage work types',
    value: CAN_MANAGE_WORKTYPES,
  },
  {
    label: 'Can manage salary extensions',
    value: CAN_MANAGE_SALARY,
  },
  {
    label: 'Login boxes management',
    value: CAN_MANAGE_BOXES,
  },
  {
    label: 'Can confirm work hours',
    value: CAN_CONFIRM_WORK_HOURS,
  },
  {
    label: 'Can accept/edit/decline requests',
    value: CAN_ACCEPT_DECLINE_REQUESTS,
  },
] as PermissionOptionType[];

export const WORKER_PERMISSIONS = [
  {
    label: 'Can register own work time from computer',
    value: CAN_REGISTER_OWN_WORK_FROM_COMPUTER,
  },
  {
    label: 'Can edit own work time',
    value: CAN_EDIT_OWN_WORK,
  },
  {
    label: 'Can register work hour from login box',
    value: CAN_REGISTER_OWN_WORK_FROM_LOGIN_BOX,
    child_permissions: [
      {
        label: 'Is visible on login box',
        value: VISIBLE_ON_LOGIN_BOX,
      },
    ],
  },
  {
    label: 'Can register own work hour through mobile',
    value: CAN_REGISTER_OWN_WORK_FROM_MOBILE,
    child_permissions: [
      {
        label: 'Can use timer functions',
        value: CAN_USE_TIMERS,
      },
      {
        label: 'Location tracking is required by owner',
        value: TRACK_LOCATION,
      },
      {
        label: 'QR login enabled',
        value: CAN_QR_LOGIN,
      },
    ],
  },
  {
    label: 'Can generate reports',
    value: CAN_GENERATE_REPORTS,
  },
  {
    label: 'Can edit own profile',
    value: CAN_EDIT_OWN_PROFILE,
  },
] as PermissionOptionType[];

export const schema = yup.object().shape({
  user_role_id: yup.number().nullable(),
  general_rights_workspace_ids: yup.array().of(yup.string()),
  manager_rights_workspace_ids: yup.array().of(yup.string()),
  // permissions: yup.array().of(yup.string().oneOf([...MANAGER_PERMISSIONS_VALUES, ...WORKER_PERMISSIONS_VALUES])),
  permissions: yup.array().of(yup.string()),
});

export type UserRightsType = yup.InferType<typeof schema>;
export interface UserRightsProps extends WrappedProps<UserRightsType> {
  row?: Identifiable;
}

export default function validator(data: UserRightsType) {
  return getValidationResult(data, schema);
}

export function getEmptyRights(): UserRightsType {
  return {
    user_role_id: null,
    general_rights_workspace_ids: [],
    manager_rights_workspace_ids: [],
    permissions: [],
  };
}
