import React, { useState, useEffect, useContext, Fragment, useMemo, useRef } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Option, FormField } from 'shared/types';
import { Props as SelectProps } from 'react-select/src/Select';

import cn from 'classnames';
// @ts-ignore
import TextFieldMessage from 'react-md/lib/TextFields/TextFieldMessage';
import styled, { ThemeContext } from 'styled-components';
import Box from '../Box';
import { FieldLabel } from '../TextField';
import _, { isEmpty, uniqBy } from 'lodash';
import { consoleLogger, getTranslatedWord } from 'shared/utils/tools';

import { isEmail } from 'pages/People/model/user_invite';
import ArrowDown from 'assets/icons/ArrowDown';
export type SelectAutocompleteProps = Omit<SelectProps, 'options'> &
  FormField & {
    hasSubOptions?: boolean;
    marginBottom?: string | number;
    isMulti?: boolean;
    isClearable?: boolean;
    isCreatable?: boolean;
    onCreateOption?: (args: any) => void;
    parentOptionColor?: string;
    subOptionColor?: string;
    selectableParent?: boolean;
    scrollOnFocus?: boolean;
    creatableLabel?: string;
    type?: 'email' | 'none';
    valueSeparator?: string;
    icon?: React.ReactElement;
    labelId?: string;
  };

type OptionType = { [key: string]: any };
type OptionsType = Array<OptionType>;

type ValueType = OptionType | OptionsType | null | void;
type ActionTypes =
  | 'clear'
  | 'create-option'
  | 'deselect-option'
  | 'pop-value'
  | 'remove-value'
  | 'select-option'
  | 'set-value';

const scrollToRef = (ref: any) => {
  const { top } = ref.current.select.inputRef.getBoundingClientRect();
  ref.current.select.inputRef.scrollIntoView({
    behavior: 'smooth',
  });
};

const Input = ({ autoComplete, ...props }: any) => <components.Input {...props} autoComplete="nope" />;

const DropdownIndicator = (props: any) => {
  const { icon, ...restProps } = props;
  return <components.DropdownIndicator {...restProps}>{icon ? icon : <ArrowDown />}</components.DropdownIndicator>;
};

export const createOption = (label: string): Option => {
  const newString = label.trim();
  return {
    label: newString,
    value: newString,
  };
};
function SelectAutocomplete(props: SelectAutocompleteProps) {
  const {
    label,
    labeldId = '',
    error,
    required,
    className,
    id,
    value,
    options = [],
    onChange,
    disabled,
    marginBottom,
    customStyles,
    isCreatable,
    hasSubOptions = true,
    parentOptionColor,
    subOptionColor,
    selectableParent = false,
    placeholder,
    scrollOnFocus,
    creatableLabel,
    type,
    icon,
    ...restProps
  } = props;
  const [currentValue, setCurrentValue] = useState<Option | Option[] | null>();
  const [inputValue, setInputValue] = useState<any>();

  const [currentOptions, setCurrentOptions] = useState<Option[]>(options);
  const additionalProps: any = {};
  const ref = useRef<any>(null);

  const formattedOptions = useMemo(() => {
    return options.reduce((newOptions: Option[], current: Option) => {
      if (current?.children && Array.isArray(current.children)) {
        const formatChildren = current.children.map(child => {
          return {
            ...child,
            isChild: true,
            hidden: true,
            parentValue: current,
          };
        });
        return newOptions.concat([...[current], ...formatChildren]);
      }

      return newOptions.concat(current);
    }, []);
  }, [options]);

  useEffect(() => {
    setCurrentOptions(formattedOptions);
  }, [formattedOptions]);

  useEffect(() => {
    if (!Array.isArray(value)) {
      const newCurrentValue = currentOptions.filter(item => item.value === value);
      setCurrentValue(newCurrentValue[0]);
    }
    setInitialValue();
  }, [currentOptions, value]);

  const theme = useContext(ThemeContext);

  const defaultCustomStyles = {
    placeholder: (styles: Object) => ({
      ...styles,
      color: theme.colors.gray.medium_dark,
    }),
    control: (styles: Object) => ({
      ...styles,
      backgroundColor: disabled ? theme.colors.gray.light : theme.colors.white,
      fontSize: '16px',
      color: theme.colors.secondary,
      minHeight: '50px',
      borderRadius: theme.borderRadius,
      border: `1px solid ${theme.colors.inputBorder}`,
      outline: '0',
      fontWeight: '500',
      boxShadow: 'none',
      ':hover': {
        border: `1px solid ${theme.colors.main}`,
        boxShadow: `0px 0px 5px 1px ${theme.colors.focusShadow}`,
      },
      active: {
        border: `1px solid ${theme.colors.main}`,
        boxShadow: `0px 0px 5px 1px ${theme.colors.focusShadow}`,
      },
      focus: {
        border: `1px solid ${theme.colors.main}`,
        boxShadow: `0px 0px 5px 1px ${theme.colors.focusShadow}`,
      },
    }),
    option: (styles: Object, { isFocused }: any) => {
      return {
        ...styles,
        color: theme.colors.gray.dark,
        backgroundColor: isFocused ? theme.colors.gray.medium : theme.colors.white,
        minHeight: '32px',
        fontSize: '16px',
        fontWeight: 500,
      };
    },
    multiValue: (styles: Object, props: any) => {
      let valueStyleTheme = {
        color: theme.colors.gray.dark,
        backgroundColor: theme.colors.inputBorder,
      };

      if (props.data?.children && Array.isArray(props.data?.children) && props?.data?.children.length > 0) {
        valueStyleTheme = {
          color: theme.colors.main,
          backgroundColor: theme.colors.secondaries.blue,
        };
      }

      if (type === 'email' && props?.data && typeof props.data.value === 'string' && !isEmail(props.data.value)) {
        valueStyleTheme = {
          color: theme.colors.caution,
          backgroundColor: theme.colors.secondaries.pink,
        };
      }
      return {
        ...styles,
        ...valueStyleTheme,
        borderRadius: theme.borderRadius,
        minHeight: '32px',
        fontSize: '16px',
        fontWeight: 500,
        alignItems: 'center',
        zIndex: 1,
      };
    },
    multiValueLabel: (styles: Object, props: any) => {
      let valueStyleTheme = {
        color: theme.colors.gray.dark,
      };
      if (props.data?.children && Array.isArray(props.data?.children) && props?.data?.children.length > 0) {
        valueStyleTheme = {
          color: theme.colors.main,
        };
      }
      if (
        type === 'email' &&
        props?.data &&
        typeof props.data.value === 'string' &&
        !props.data.value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        valueStyleTheme = {
          color: theme.colors.caution,
        };
      }

      return {
        ...styles,
        ...valueStyleTheme,
      };
    },
  };

  const renderOption = (
    option: Option & { children?: Option[]; parent_id?: number; isChild?: boolean; hasChildren?: boolean },
    callback?: (value: ValueType, action: ActionTypes, option?: Option) => void,
  ): any => {
    const handleSelectOption = () => {
      const curValue = currentValue ? (Array.isArray(currentValue) && currentValue.length > 0 ? currentValue : []) : [];

      const children = option.children?.filter((o: Option) => {
        const exist = curValue.findIndex((p: Option) => o.value === p.value);
        return exist <= -1;
      });

      if (option?.isChild) {
        if (option?.parentValue && !curValue.includes(option?.parentValue)) {
          callback &&
            callback(
              props.isMulti ? [...curValue, ...[option?.parentValue], ...[option]] : { ...option },
              'select-option',
            );
        } else {
          callback && callback(props.isMulti ? [...curValue, ...[option]] : { ...option }, 'select-option');
        }
      } else {
        callback &&
          (selectableParent || !(Array.isArray(option?.children) && option?.children.length !== 0)) &&
          callback(
            props.isMulti ? [...curValue, ...[option], ...[...(children || [])]] : { ...option },
            'select-option',
          );
      }
    };

    return (
      <Fragment key={`${option?.value}`}>
        <OptionContainer
          onClick={handleSelectOption}
          parentOptionColor={parentOptionColor || theme.colors.lightGrey}
          subOptionColor={subOptionColor || theme.colors.main}
          isChild={option?.isChild}
          hasChildren={Number(option?.children?.length) > 0}
        >
          {option.label}
        </OptionContainer>
      </Fragment>
    );
  };

  const CustomOption = (props: any) => {
    const { data, setValue } = props;
    return renderOption(data, setValue);
  };

  if (hasSubOptions) {
    additionalProps['components'] = {
      Option: CustomOption,
    };
  }

  return (
    <SelectAutoCompleteContainer marginBottom={marginBottom} inputType={type} caution={Boolean(error)}>
      {label && (
        <FieldLabel id={props.labelId} caution={Boolean(error)}>
          {label}
        </FieldLabel>
      )}
      <div>
        <div>
          {isCreatable ? (
            <CreatableSelect
              id={id}
              ref={ref}
              value={currentValue}
              defaultValue={currentValue}
              options={currentOptions}
              inputValue={inputValue}
              onInputChange={value => {
                setInputValue(value);
              }}
              onChange={handleChange}
              isDisabled={disabled}
              className={'select-field' + (disabled ? ' disabled' : '')}
              classNamePrefix="select-field"
              styles={customStyles || defaultCustomStyles}
              placeholder={placeholder || label}
              onFocus={() => {
                if (ref && ref.current && scrollOnFocus) {
                  scrollToRef(ref);
                }
              }}
              inputProps={{
                autoComplete: 'off',
              }}
              components={{
                Input,
                DropdownIndicator: indicatorProps => <DropdownIndicator {...indicatorProps} icon={icon} />,
                ...additionalProps.components,
              }}
              formatCreateLabel={(value: string) => (creatableLabel ? `${creatableLabel} - ${value}` : value)}
              onCreateOption={handleCreate}
              onKeyDown={(e: any) => {
                if (e?.which === 188) {
                  e.preventDefault();
                  handleCreate(inputValue);
                  setInputValue('');
                }
              }}
              {...restProps}
            />
          ) : (
            <Select
              id={id}
              ref={ref}
              value={currentValue}
              defaultValue={currentValue}
              options={currentOptions}
              onChange={handleChange}
              isDisabled={disabled}
              className={'select-field' + (disabled ? ' disabled' : '')}
              classNamePrefix="select-field"
              styles={customStyles || defaultCustomStyles}
              placeholder={placeholder || label}
              inputProps={{
                autoComplete: 'off',
              }}
              components={{
                Input,
                DropdownIndicator: indicatorProps => <DropdownIndicator {...indicatorProps} icon={icon} />,
                ...additionalProps.components,
              }}
              onFocus={() => {
                if (ref && ref.current && scrollOnFocus) {
                  scrollToRef(ref);
                }
              }}
              {...restProps}
            />
          )}
        </div>
        <TextFieldMessage errorText={getTranslatedWord(error)} error={!!error} />
      </div>
    </SelectAutoCompleteContainer>
  );

  async function setInitialValue() {
    if (isNotEqualValue()) {
      if (props.isMulti) {
        const filtered = value?.map(async (i: Option) => {
          const j = await findValueInArray(currentOptions, i);
          return j;
        });

        // const validResults = results.filter(result => !(result instanceof Error))

        const results = await Promise.all(filtered || []);

        setCurrentValue(results.filter(o => o !== null) as Option[]);
      } else {
        // const selected = currentOptions.find(e => e.value === value)
        if (value) {
          const selected = await findValueInArray(currentOptions, value);
          setCurrentValue(selected || null);
        } else {
          setCurrentValue(null);
        }
      }
    }
  }

  function handleCreate(inputValue: string) {
    if (!isEmpty(inputValue.trim())) {
      const inputValues = inputValue.split(',');

      if (inputValues) {
        const formattedInputValues = inputValues.flatMap(createOption);
        setCurrentOptions(prevOptions => [...prevOptions, ...formattedInputValues]);
        consoleLogger(formattedInputValues, currentValue, '@value');

        if (props?.isMulti && Array.isArray(currentValue)) {
          const uniqueValues = uniqBy([...currentValue, ...formattedInputValues], option => option.value);
          handleChange(uniqueValues);
        }
      }
    }

    return;
  }

  function isNotEqualValue() {
    const result = (value && !currentValue) || (!value && currentValue);
    if (value && currentValue) {
      if (props.isMulti && Array.isArray(currentValue))
        return value.join(',') !== currentValue.map(e => (typeof e === 'object' ? e.value : e)).join(',');

      if ('value' in currentValue) return value !== currentValue.value;
    }
    return result;
  }

  function handleChange(newVal: any, actionType?: any) {
    if (props.isMulti) {
      switch (actionType?.action) {
        case 'remove-value':
          if (actionType?.removedValue?.children && Array.isArray(actionType?.removedValue?.children)) {
            consoleLogger(actionType?.removedValue?.children, '@children');
            consoleLogger(newVal, '@newVal');

            onChange(
              Array.isArray(newVal)
                ? newVal
                    ?.filter(
                      val =>
                        actionType?.removedValue?.children?.filter((val2: any) => val.value === val2.value).length ===
                        0,
                    )
                    ?.map(e => e.value)
                : [],
              id,
            );
            setCurrentValue(
              Array.isArray(newVal)
                ? [
                    ...newVal?.filter(
                      val =>
                        actionType?.removedValue?.children?.filter((val2: any) => val.value === val2.value).length ===
                        0,
                    ),
                  ]
                : [],
            );
          } else {
            onChange(Array.isArray(newVal) ? newVal.map(e => e.value) : [], id);
            setCurrentValue(Array.isArray(newVal) ? [...newVal] : []);
          }
          break;
        default:
          onChange(Array.isArray(newVal) ? newVal.map(e => e.value) : [], id);
          setCurrentValue(Array.isArray(newVal) ? [...newVal] : []);
      }
    } else {
      onChange(newVal && 'value' in newVal ? newVal.value || '' : '', id);
      setCurrentValue(newVal);
      setInputValue('');
    }
  }

  async function findValueInArray(array: Option[], value: any) {
    return new Promise<Option | null>((resolve, reject) => {
      const exist = array.find(option => {
        return String(option.value) === String(value);
      });
      if (!exist) {
        array.map(async option => {
          if (option.children && option.children.length !== 0) {
            const exist2 = await findValueInArray(option.children, value);
            resolve(exist2);
          }

          resolve(null);
        });
      } else {
        resolve(exist);
      }
    });
  }
}

const OptionContainer = styled(Box)<{
  subOptionColor?: string;
  parentOptionColor?: string;
  isChild?: boolean;
  hasChildren?: boolean;
}>`
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  padding-left: ${({ isChild }) => (isChild ? 'calc(16px * 2)' : '16px')};
  align-items: center;
  font-weight: 400;
  color: ${({ hasChildren, isChild, subOptionColor, parentOptionColor }) =>
    hasChildren && !isChild ? parentOptionColor : subOptionColor} !important;

  &:hover {
    cursor: ${({ hasChildren, isChild }) => (hasChildren && !isChild ? 'default' : 'pointer')};
    background-color: ${({ theme, hasChildren, isChild }) =>
      hasChildren && !isChild ? 'unset' : theme.colors.gray.light_gray};
  }

  &.parent-option {
    color: ${({ parentOptionColor }) => parentOptionColor} !important;
    cursor: auto;
  }
`;

const SelectAutoCompleteContainer = styled(Box)<{
  inputType?: string;
  caution?: boolean;
}>`
  width: 100%;

  .select-field {
    &__menu {
      z-index: 2;
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
    }

    &__placeholder {
      margin-left: 8px;
    }

    &__value-container {
      margin-left: 8px;

      .select-field__placeholder {
        margin-left: 0px !important;
      }
    }

    &__control {
      ${({ theme, caution }) => `
        border: 1px solid  ${caution ? theme.colors.caution : theme.colors.inputBorder} !important;
      `}

      &:hover {
        border: ${({ theme }) => `1px solid ${theme.colors.main} !important`};
        box-shadow: 0px 0px 5px 1px rgba(149, 53, 143, 0.2);
        border-radius: 4px;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      display: ${({ inputType }) => (inputType === 'email' ? 'none' : 'flex')};
      margin-right: 8px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__menu-list {
      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.label};
        border-radius: 50px;
      }
    }

    &__option {
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      height: 40px;
      display: flex;
      align-items: center;
      // color: #353B5A;
    }

    &__multi-value__remove {
      &:hover {
        background-color: #353b5a29;
        color: #fff;
        cursor: pointer;
      }

      svg {
        height: 32px;
      }
    }
  }

  &:has(.disabled) {
    cursor: not-allowed;

    &:hover {
      .select-field__control--is-disabled {
        border: ${({ theme }) => `1px solid ${theme.colors.main} !important`};
      }
    }
  }
`;

SelectAutocomplete.defaultProps = {
  disabled: false,
  required: true,
  className: '',
  error: '',
  options: [],
};

export default SelectAutocomplete;
